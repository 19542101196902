<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="contentForSelectedContentTypeSorted"
      :pagination="false"
    >
      <div slot="actions" slot-scope="text, record">
        <a-button type="primary" @click.prevent="handleTopicSelected(record)"
          >View</a-button
        >
      </div>
    </a-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const _ = require("lodash");
export default {
  data() {
    return {
      columns: [
        {
          key: "name",
          title: "Name",
          dataIndex: "$v.displayName",
        },
        {
          key: "actions",
          scopedSlots: { customRender: "actions" },
          title: "Actions",
          width: 100,
        },
      ],
    };
  },

  methods: {
    ...mapActions('guidancePackViewer', {
      // handleTopicSelected: 'handleTopicSelected'
      selectTopic: 'selectTopic'
    }),

    handleTopicSelected(topic) {
      this.selectTopic(topic['$v']['id'])
    }
  },

  computed: {
    ...mapGetters("guidancePackViewer", {
      selectedContentType: "selectedContentType",
      contentForSelectedContentType: "contentForSelectedContentType",
    }),

    contentForSelectedContentTypeSorted() {
      return _.orderBy(
        this.contentForSelectedContentType,
        ["$v.rank", "$v.displayName"],
        ["desc", "asc"]
      );
    },
  },
};
</script>

<style>
</style>