<template>
  <div>
    <a-table :data-source="dataSource" :pagination="false" :columns="columns">
      <div slot="actions" slot-scope="text, record">
        <a-button type="primary" @click.prevent="selectContentType(record.contentType)"
          >View</a-button
        >
      </div>
    </a-table>
  </div>
</template>

<script>
import {mapActions} from "vuex";

const COLUMNS = [
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
  },
  {
    key: "actions",
    scopedSlots: { customRender: "actions" },
    title: "Actions",
    width: 100,
  },
];

export default {
  props: ["guidancePack"],
  data() {
    return {
      columns: COLUMNS,
    };
  },
  methods: {
    ...mapActions("guidancePackViewer", {
      selectContentType: "selectContentType"
    })
  },
  computed: {
    dataSource() {
      return [
        {
          name: "All Articles",
          contentType: {
            model: "Soteria.Models.ArticleDetails, Soteria.Models",
            name: "Articles",
          },
        },
        {
          name: "All Help Videos",
          contentType: {
            model: "Soteria.Models.HelpVideoDetails, Soteria.Models",
            name: "Help Videos",
          },
        },
        {
          name: "All Downloads",
          contentType: {
            model: "Soteria.Models.PolicyDetails, Soteria.Models",
            name: "Downloads",
          },
        },
        {
          name: "All External Links",
          contentType: {
            model: "Soteria.Models.ExternalLinkDetails, Soteria.Models",
            name: "External Links",
          },
        },
        {
          name: "All Playbooks",
          contentType: {
            model: "Soteria.Models.ChecklistContentDetails, Soteria.Models",
            name: "Playbooks",
          },
        },
      ];
    },
  },
};
</script>

<style>
</style>