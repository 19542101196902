<template>
  <senso-layout>
    <div class="senso-guidance">
      <!-- Loading -->
      <div class="loader" v-if="isLoadingCombined">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- Loaded -->
      <a-row :gutter="20" v-if="!isLoadingCombined">
        <a-col :span="6">
          <div class="card">
            <div class="header">
              <h3>Guidance</h3>
            </div>
            <div class="body">
              <a-tree
                :expandedKeys="expandedKeys"
                :selectedKeys="selectedKeys"
                :tree-data="treeData"
                @expand="onExpand"
                @select="onSelect"
              >
                <template #title="{ title, key }">
                  <span v-if="key === '0-0-1-0'" style="color: #1890ff">{{
                    title
                  }}</span>
                  <template v-else>{{ title }}</template>
                </template>
              </a-tree>
            </div>
          </div>
        </a-col>
        <a-col :span="18">
          <!-- No guidance pack selected -->
          <div class="card" v-if="!selectedGuidancePack">
            <div class="header">
              <h3>All Guidance Packs</h3>
            </div>
            <div class="body">
              <a-table
                :data-source="guidancePacksSorted"
                :pagination="false"
                :columns="guidancePackColumns"
              >
                <div slot="actions" slot-scope="text, record">
                  <a-button
                    type="primary"
                    @click.prevent="selectGuidancePack(record)"
                    >View</a-button
                  >
                </div>
              </a-table>
            </div>
          </div>
          <!-- / No guidance pack selected -->

          <!-- Guidance pack selected -->
          <div class="card" v-if="selectedGuidancePack">
            <div class="header">
              <h3>
                {{ guidancePackCardTitle }}
              </h3>
            </div>
            <div class="body">
              <!-- Is Loading Content -->
              <div v-if="isLoadingGuidancePack">
                <a-spin></a-spin>
              </div>
              <!-- / Is Loading Content -->

              <!-- Loaded -->
              <div v-if="!isLoadingGuidancePack">
                <!-- No topic or parent selected -->
                <div v-if="!(selectedTopic || selectedParent)">
                  <!-- No content type selected -->
                  <div v-if="!selectedContentType">
                    <category-selector
                      :guidance-pack="selectedGuidancePack"
                    ></category-selector>
                  </div>
                  <!-- / No content type selected -->

                  <!-- Content type selected -->
                  <div v-if="selectedContentType">
                    <content-type-page></content-type-page>
                  </div>
                  <!-- / Content type selected -->
                </div>
                <!-- / No topic or parent selected -->

                <!-- Topic Selected - Not Modal View -->
                <topic-tabs v-if="selectedTopic" :no-header="true"></topic-tabs>
                <!-- / Topic Selected - Not Modal View -->

                <!-- Topic selected - modal view -->
                <!-- <topic-modal
                  v-if="selectedTopic && selectedTopicShouldBeDisplayedInModal"
                ></topic-modal> -->
                <!-- / Topic selected - modal view -->
              </div>
              <!-- / Loaded -->
            </div>
          </div>
          <!-- / Guidance pack selected -->
        </a-col>
      </a-row>
      <!-- / Loaded -->
    </div>
  </senso-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SensoLayout from "./SensoLayout.vue";
const _ = require("lodash");
import CategorySelector from "../../components/Senso/Guidance/CategorySelector.vue";
import ContentTypePage from "../../components/Senso/Guidance/ContentTypePage.vue";
import TopicTabs from "../Guidance/Show/TopicTabs.vue";
// import TopicModal from "../Guidance/Show/TopicModal.vue";
export default {
  data() {
    return {
      selectedGuidancePack: null,

      expandedKeys: [],
      selectedKeys: [],

      guidancePackColumns: [
        {
          key: "name",
          title: "Name",
          dataIndex: "$v.displayName",
        },
        {
          key: "actions",
          scopedSlots: { customRender: "actions" },
          title: "Actions",
          width: 100,
        },
      ],
      guidancePackData: [
        {
          name: "Cyber Safety In Schools",
        },
        {
          name: "Care Homes",
        },
      ],

      showTab: "article",
      resourcesColumns: [
        {
          key: "name",
          title: "Name",
          dataIndex: "name",
        },
        {
          key: "type",
          title: "Type",
          dataIndex: "type",
        },
        {
          key: "actions",
          title: "Actions",
          width: 100,
        },
      ],
      resourcesData: [
        {
          name: "What to do if you make a mistake",
          type: "Help Video",
        },
        {
          name: "Is this a fake website?",
          type: "Help Video",
        },
        {
          name: "Compromised credentials task list",
          type: "Document",
        },
      ],
    };
  },
  created() {
    this.loadGuidancePacks();
  },
  components: { SensoLayout, CategorySelector, ContentTypePage, TopicTabs },
  computed: {
    ...mapGetters("missionControl", {
      isLoadingMissionControl: "isLoading",
    }),

    ...mapGetters("guidance", {
      guidancePacks: "guidancePacks",
      guidancePacksSorted: "guidancePacksSorted",
      isLoading: "isLoading",
    }),

    ...mapGetters("guidancePackViewer", {
      guidancePack: "guidancePack",
      guidancePackContents: "guidancePackContents",
      isLoadingGuidancePack: "isLoading",
      topics: "topics",
      selectedContentType: "selectedContentType",
      selectedParent: "selectedParent",
      selectedTopic: "selectedTopic",
      selectedTopicShouldBeDisplayedInModal:
        "selectedTopicShouldBeDisplayedInModal",
    }),

    isLoadingCombined() {
      return this.isLoading || this.isLoadingMissionControl;
    },

    treeData() {
      if (this.guidancePacks) {
        return _.map(this.guidancePacksSorted, (gp) => {
          return {
            title: gp["$v"]["displayName"],
            key: "gp-" + gp["$v"]["id"],
            children: [
              {
                title: "All Articles",
                key: `gp-${gp["$v"]["id"]}-all-articles`,
              },
              {
                title: "All Help Videos",
                key: `gp-${gp["$v"]["id"]}-all-help-videos`,
              },
              {
                title: "All Downloads",
                key: `gp-${gp["$v"]["id"]}-all-downloads`,
              },
              {
                title: "All External Links",
                key: `gp-${gp["$v"]["id"]}-all-external-links`,
              },
              {
                title: "All Playbooks",
                key: `gp-${gp["$v"]["id"]}-all-playbooks`,
              },
            ],
          };
        });
      }
      return [];
    },

    guidancePackCardTitle() {
      if (this.selectedContentType) {
        return this.selectedContentType.name;
      }
      if (this.selectedGuidancePack) {
        return this.selectedGuidancePack["$v"]["displayName"];
      }
      return "Unknown";
    },
  },
  methods: {
    ...mapActions("guidance", {
      loadGuidancePacks: "loadGuidancePacks",
      loadLastViews: "loadLastViews",
    }),

    ...mapActions("guidancePackViewer", {
      loadGuidancePack: "loadGuidancePack",
      selectTopic: "selectTopic",
      selectContentType: "selectContentType",
    }),

    selectGuidancePack(guidancePack, updateKeys = true) {
      this.selectedGuidancePack = guidancePack;

      if (updateKeys) {
        this.selectedKeys = ["gp-" + guidancePack["$v"]["id"]];
        this.expandedKeys = ["gp-" + guidancePack["$v"]["id"]];
      }

      this.selectTopic(null);
      this.loadGuidancePack({
        tenantId: guidancePack["$v"]["tenantId"],
        id: guidancePack["$v"]["id"],
      });
    },

    onExpand(expandedKeys) {
      console.log("onExpand", expandedKeys);
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys) {
      console.log("onCheck", checkedKeys);
      this.checkedKeys = checkedKeys;
    },
    onSelect(selectedKeys, info) {
      console.log("onSelect", info);
      this.selectedKeys = selectedKeys;
      if (this.selectedKeys.length) {
        let guidancePackId = this.selectedKeys[0]
          .split("-all")[0]
          .replace("gp-", "");
        if (guidancePackId) {
          let guidancePack = _.find(this.guidancePacks, function (gp) {
            return gp["$v"]["id"] == guidancePackId;
          });
          this.selectGuidancePack(guidancePack, false);
           this.selectTopic(null);
          // this.expandedKeys = [this.selectedKeys[0]];

          if (this.selectedKeys[0].includes("all")) {
            // All articles
            if (this.selectedKeys[0].includes("all-articles")) {
              this.selectContentType({
                model: "Soteria.Models.ArticleDetails, Soteria.Models",
                name: "Articles",
              });
            }

            // All help videos
            if (this.selectedKeys[0].includes("all-help-videos")) {
              this.selectContentType({
                model: "Soteria.Models.HelpVideoDetails, Soteria.Models",
                name: "Help Videos",
              });
            }

            // All downloads
            if (this.selectedKeys[0].includes("all-downloads")) {
              this.selectContentType({
                model: "Soteria.Models.PolicyDetails, Soteria.Models",
                name: "Help Videos",
              });
            }

            // All external links
            if (this.selectedKeys[0].includes("all-external-links")) {
              this.selectContentType({
                model: "Soteria.Models.ExternalLinkDetails, Soteria.Models",
                name: "External Links",
              });
            }

            // All playbooks
            if (this.selectedKeys[0].includes("all-playbooks")) {
              this.selectContentType({
                model: "Soteria.Models.ChecklistContentDetails, Soteria.Models",
                name: "Playbooks",
              });
            }
          } 
        }
      } else {
        this.selectTopic(null);
        this.selectContentType(null);
        this.selectedGuidancePack = null;
      }
    },
  },
};
</script>

<style>
</style>